import {SET_LOADING, SET_SALARIO, SET_LOADING_ARCHIVO_PAGO, SET_ARCHIVO_PAGO} from './constants'
export default {
  [SET_SALARIO](state, request) {
    state.salario = request;
  },
  [SET_LOADING](state, request) {
    state.isLoading = request;
  },
  [SET_LOADING_ARCHIVO_PAGO](state, request) {
    state.isArchivoTrabajoLoading = request;
  },
  [SET_ARCHIVO_PAGO](state, request) {
    state.archivoTrabajo = request;
  }
}
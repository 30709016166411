<template>
    <v-dialog v-model="value" max-width="500">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>{{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-container>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field v-model="data.descripcion" label="Descripcion"
                                    :rules="[(v) => !!v || 'Este campo es requerido']" ref="feriado1"
                                    @keyup.enter="next(1, data.descripcion)"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <c-text-date ref="feriado2" label="Fecha" v-model="data.fecha"
                                    @keyup.native.enter="next(2, data.fecha)"></c-text-date>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
                <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
                <v-spacer></v-spacer>
                <c-btn-crud-done ref="feriado3" @click="save()"></c-btn-crud-done>
            </v-card-actions>
            <c-overlay :value="isFeriadoLoading"></c-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        props: {
            type: Object,
        },
        "sync-input": {
            type: [String, Number],
            default: null,
        },
    },

    mounted() {
        if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
        setTimeout(() => this.next(0, "-"), 20);
    },
    data() {
        return {
            valid: true,
            data: {
                idferiado: 0,
                descripcion: "",
                fecha: "",
            },
            rules: [(v) => !!v || "Este campo es requerido"],
        };
    },
    computed: {
        ...mapGetters("feriado", ["isFeriadoLoading"]),
        title() {
            return this.editable
                ? "Modificar Feriado"
                : "Registrar Feriado";
        },
    },
    methods: {
        ...mapActions("feriado", ["fetchFeriado", "setFeriado"]),
        next(id, data) {
            if (data != "")
                try {
                    this.$refs[`feriado${id + 1}`].focus();
                } catch (error) {
                    this.$refs[`feriado${id + 1}`].$el.focus();
                }
        },
        async save() {
            if (!this.$refs.form.validate()) return null;
            const response = this.editable
                ? await this.setFeriadoUpdate({
                    data: this.data,
                    id: this.data.idferiado,
                })
                : await this.setFeriado(this.data);
            if (response.success) {
                this.fetchFeriado();
                if (this.sync != null) {
                    this.cancel();
                    return this.$emit("sync-input", response.data.idferiado);
                }
                this.data = JSON.parse(JSON.stringify(this.default));
                this.$refs.form.resetValidation();
                this.next(0, "-");
                if (this.editable) this.cancel();
            }
        },
        cancel() {
            this.$emit("input", false);
        },
    },
};
</script>
import { get, post, put, del } from "@/services/api/api.service";
import { SET_LOADING, SET_SALARIO, SET_LOADING_ARCHIVO_PAGO, SET_ARCHIVO_PAGO, url, url2 } from "./constants";
export default {
  async fetchSalario({ commit, dispatch }, {  desde, hasta, sector }) {
    if (!desde) desde = "";
    if (!hasta) hasta = "";
    if (!sector) sector = "";
    commit(SET_LOADING, true);
    try {
      const response = await get(
        `${url}?inicio=${desde}&fin=${hasta}&sector=${sector}`
      );
      commit(SET_SALARIO, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(SET_LOADING, false);
      throw e;
    }
    commit(SET_LOADING, false);
  },
  async setSalario({ commit, dispatch }, data) {
    commit(SET_LOADING, true);
    const response = await post(url, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },
  async setSalarioUpdate({ commit, dispatch }, { data, id }) {
    commit(SET_LOADING, true);
    const response = await put(`${url}${id}/`, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },
  async setSalarioDelete({ commit, dispatch }, id) {
    commit(SET_LOADING, true);
    const response = await del(`${url}${id}/`);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },
  async fetchArchivoPago({commit, dispatch}, {desde, hasta}) {
    commit(SET_LOADING_ARCHIVO_PAGO, true);
    try {
      const response = await get(
        `${url2}?inicio=${desde}&fin=${hasta}`
      );
      commit(SET_ARCHIVO_PAGO, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(SET_LOADING_ARCHIVO_PAGO, false)
      throw e;
    }
    commit(SET_LOADING_ARCHIVO_PAGO, false)
  }
};

import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { current_date } from "@/services/util/date.service";
import { currency } from "@/services/util/number.service";
import store from '@/store'
const exportAvanzado = (title, fecha, reporteEspecifico, resumen, cotizacion) => {

    var doc = new jsPDF();
    let pageWidth = doc.internal.pageSize.width;
    let fechaWidth = doc.getTextWidth(fecha) - 13;
    doc.setFontSize(10);
    store.getters['empresa/Vget_empresa_active'].forEach(empresa => {
        if (empresa.est_emp_tam_usu)
            return doc.text(empresa.idempresa.descempresa, 15, 20);
    })
    doc.text(fecha, pageWidth - fechaWidth, 20);
    doc.setLineWidth(0.5);
    doc.setFontSize(14);
    doc.text(title, doc.internal.pageSize.width / 2, 30, { align: 'center' });
    doc.setFontSize(12);
    if (cotizacion > 0) doc.text('Cotizacion: ' + cotizacion, doc.internal.pageSize.width / 2, 36, { align: 'center' });
    doc.setFontSize(10);
    let coordinateY;
    resumen.map((res) => {
        coordinateY = doc.previousAutoTable.finalY || 28;
        doc.text(res.title, doc.internal.pageSize.width - 35, coordinateY + 10, { align: 'center' });
        doc.setLineWidth(0.7);
        doc.line(13, coordinateY + 11, doc.internal.pageSize.width - 12, coordinateY + 11);
        let body = res.items.map(x => {
            return [
                x.name,
                currency(x.value)
            ]
        })
        if (res.total) body.push({ name: 'Total', value: currency(res.total) })
        doc.autoTable({
            columns: [
                { dataKey: 'name' },
                { dataKey: 'value' }
            ],
            head: [['Descripcion', 'Total']],
            body: body,
            startY: coordinateY + 12,
            theme: 'grid',
            headStyles: {
                textColor: [0, 0, 0],
                fillColor: [255, 255, 255],
                fontSize: 8,
            },
            columnStyles: {
                1: { halign: 'right', fontStyle: 'bold' }
            },
            styles: { overflow: 'hidden' },
            didParseCell: (data) => {
                if (data.row.index == body.length - 1 && data.column.index == 0) {
                    if (data.cell.raw === 'Total') {
                        data.row.cells[0].colSpan = 1;
                        Object.entries(data.row.cells).forEach((v) => {
                            v[1].styles.fontStyle = 'bold';
                        })
                    }
                }
            }
        })
    })

    reporteEspecifico.forEach((x, index) => {
        if (index > 0) {
            doc.previousAutoTable.finalY = 10;
            doc.previousAutoTable.finalY = 0;
        }
        doc.text('Lote: ' + x.gasto_body[0][0], 15, doc.previousAutoTable.finalY + 10)
        doc.text('Resumen de Lotes', doc.internal.pageSize.width - 30, doc.previousAutoTable.finalY + 10, { align: 'center' });
        doc.setLineWidth(0.7);
        doc.line(13, doc.previousAutoTable.finalY + 11, doc.internal.pageSize.width - 12, doc.previousAutoTable.finalY + 11);

        doc.autoTable({
            head: x.gasto_head,
            body: x.gasto_body,
            startY: doc.previousAutoTable.finalY + 12,
            theme: 'grid',
            columnStyles: {
                8: { halign: 'right', fontStyle: 'bold' },
                9: { halign: 'right', fontStyle: 'bold' },
            },
            bodyStyles: {
                fontSize: 7,
            },
            headStyles: {
                textColor: [0, 0, 0],
                fillColor: [255, 255, 255],
                fontSize: 6,
            },
        });

        doc.text('Lote: ' + x.gasto_body[0][0], 15, doc.previousAutoTable.finalY + 10)
        doc.text('Resumen de Cosecha', doc.internal.pageSize.width - 30, doc.previousAutoTable.finalY + 10, { align: 'center' });
        doc.setLineWidth(0.7);
        doc.line(13, doc.previousAutoTable.finalY + 11, doc.internal.pageSize.width - 12, doc.previousAutoTable.finalY + 11);
        doc.autoTable({
            head: x.ingreso_head,
            body: x.ingreso_body,
            startY: doc.previousAutoTable.finalY + 12,
            theme: 'grid',
            columnStyles: { 6: { halign: 'right', fontStyle: 'bold' } },
            headStyles: {
                textColor: [0, 0, 0],
                fillColor: [255, 255, 255],
                fontSize: 8,
            },
        });
        doc.text('Lote: ' + x.gasto_body[0][0], 15, doc.previousAutoTable.finalY + 10)
        doc.text('Lista de Aplicaciones', doc.internal.pageSize.width - 30, doc.previousAutoTable.finalY + 10, { align: 'center' });
        doc.setLineWidth(0.7);
        doc.line(13, doc.previousAutoTable.finalY + 11, doc.internal.pageSize.width - 12, doc.previousAutoTable.finalY + 11);
        doc.autoTable({
            head: x.lista_head,
            body: x.lista_body,
            startY: doc.previousAutoTable.finalY + 12,
            columnStyles: { 8: { halign: 'right', fontStyle: 'bold' } },
            theme: 'grid',
            headStyles: {
                textColor: [0, 0, 0],
                fillColor: [255, 255, 255],
                fontSize: 8,
            },
        });
        if (reporteEspecifico[index + 1] != undefined)
            doc.addPage();
    })
    doc.save(title + ' ' + current_date() + '.pdf');
}
export const exportResumenLote = (title, fecha, data, cotizacion) => {
    var doc = new jsPDF();
    let pageWidth = doc.internal.pageSize.width;
    let fechaWidth = doc.getTextWidth(fecha) - 13;
    doc.setFontSize(10);
    store.getters['empresa/Vget_empresa_active'].forEach(empresa => {
        if (empresa.est_emp_tam_usu)
            return doc.text(empresa.idempresa.descempresa, 15, 20);

    })
    doc.text(fecha, pageWidth - fechaWidth, 20);
    doc.setLineWidth(0.5);
    doc.setFontSize(14);
    doc.text(title, doc.internal.pageSize.width / 2, 30, { align: 'center' });
    doc.setFontSize(12);
    if (cotizacion > 0) doc.text('Cotizacion: ' + cotizacion, doc.internal.pageSize.width / 2, 36, { align: 'center' });
    let coordinateY = 35;
    doc.setLineWidth(0.7);
    doc.line(13, coordinateY + 11, doc.internal.pageSize.width - 12, coordinateY + 11);

    doc.autoTable({
        head: data.gasto_head,
        body: data.gasto_body,
        startY: coordinateY + 12,
        theme: 'grid',
        columnStyles: {
            8: { halign: 'right', fontStyle: 'bold' },
            9: { halign: 'right', fontStyle: 'bold' },
        },
        bodyStyles: {
            fontSize: 4,
        },
        headStyles: {
            textColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            fontSize: 4,
        },
    });
    coordinateY = doc.previousAutoTable.finalY;

    doc.save(title + ' ' + current_date() + '.pdf');
}
export const exportAplicaciones = (title, info, data) => {
    var doc = new jsPDF();
    let pageWidth = doc.internal.pageSize.width;
    let fechaWidth = doc.getTextWidth(info.fecha) - 13;
    doc.setFontSize(10);
    store.getters['empresa/Vget_empresa_active'].forEach(empresa => {
        if (empresa.est_emp_tam_usu)
            return doc.text(empresa.idempresa.descempresa, 15, 20);

    })
    doc.text(info.fecha, pageWidth - fechaWidth, 20);

    doc.text('Zona: ' + info.grupo, 15, 28);
    doc.text('Lote: ' + info.lote, 15, 33);

    doc.setLineWidth(0.5);
    doc.setFontSize(14);
    doc.text(title, doc.internal.pageSize.width / 2, 29, { align: 'center' });
    doc.setFontSize(11);
    doc.text('Cotizacion: ' + info.cotizacion, doc.internal.pageSize.width / 2, 35, { align: 'center' });
    doc.setFontSize(10);
    let coordinateY = 37;
    doc.setLineWidth(0.7);
    doc.line(13, coordinateY, doc.internal.pageSize.width - 12, coordinateY);
    doc.autoTable({
        head: data.head,
        body: data.body,
        startY: coordinateY + 1,
        columnStyles: { 8: { halign: 'right', fontStyle: 'bold' }, 10: { halign: 'right', fontStyle: 'bold' } },
        theme: 'grid',
        headStyles: {
            textColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            fontSize: 7,
        },
        bodyStyles: {
            fontSize: 7,
        },
    });
    doc.save(title + ' ' + current_date() + '.pdf');
}
export const exportUltimaAplicacion = (title, data) => {
    var doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(title, doc.internal.pageSize.width / 2, 30, { align: 'center' });
    doc.text('Campaña: ' + data.zafra, 30, 40);
    doc.text('Producto: ' + data.item, 30, 50);
    doc.text('Composición: ' + data.composicion, 30, 60);
    doc.text('Fecha Consulta: ' + data.fecha_consulta, 30, 70);
    doc.text('Última Aplicación: ' + data.ultima_aplicacion, 90, 70);
    doc.text('Hace: ' + data.ultima_dias_atras, 150, 70);
    let detalle = data.detalle.map(x => {
        return [
            x.lote,
            x.fecha,
            x.hace,
            x.ha,
            x.cantidad
        ]
    })
    doc.autoTable({
        head: [['Lote', 'Fecha', 'Hace', 'Ha', 'Cantidad x ha']],
        body: detalle,
        startY: 80,
        theme: 'grid',
        headStyles: {
            textColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            fontSize: 8,
        },
    })
    doc.save(title + ' ' + current_date() + '.pdf');
}
export default exportAvanzado;

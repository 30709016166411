<template>
    <c-dialog-confirmation :value="value" @cancel="close()" @done="save()" :loading="isFeriadoLoading">Desea eliminar el
        feriado <strong>{{ props.descripcion }}</strong>?</c-dialog-confirmation>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    props: {
        value: Boolean,
        props: Object,
    },
    computed: {
        ...mapGetters('feriado', ["isFeriadoLoading"])
    },
    watch: {
        $route() {
            return this.close();
        },
    },
    methods: {
        ...mapActions("feriado", ["setFeriadoDelete", "fetchFeriado"]),

        async save() {
            const id = this.props.idferiado;
            const response = await this.setFeriadoDelete(id);
            if (!response.success) return null;
            this.close();
        },
        close() {
            this.$emit('datos', null);
            this.$emit("input", false);
            this.fetchFeriado();
        },
    }
}
</script>
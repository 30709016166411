import { get, post, put, del } from "@/services/api/api.service";
import { SET_LOADING, SET_FERIADO, url } from './constants';
export default {
    async fetchFeriado({ commit, dispatch }) {
        commit(SET_LOADING, true);
        try {
            const response = await get(url);
            commit(SET_FERIADO, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    },
    async setFeriado({ commit, dispatch }, data) {
        commit(SET_LOADING, true);
        const response = await post(url, data);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false);
        return response
    },
    async setFeriadoUpdate({ commit, dispatch }, {data,id}) {
        commit(SET_LOADING, true);
        const response = await put(`${url}${id}/`, data);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false);
        return response
    },
    async setFeriadoDelete({ commit, dispatch }, id) {
        commit(SET_LOADING, true);
        const response = await del(`${url}${id}/`);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false)
        return response;
    },
}
<template>
    <v-btn color="primary" small @click="$emit('click')"
      ><v-icon color="black">cloud_download</v-icon></v-btn
    >
  </template>
  
  <script>
  export default {};
  </script>
  
  <style>
  </style>
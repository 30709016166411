<template>
    <div>
        <v-alert type="error" v-if="!permission.reporte">Error!!! Este usuario no tiene permisos en esta ventana</v-alert>
        <v-toolbar flat dense>
            <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
            <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-text-field autocomplete="off" :rules="validar" hide-details single-line label="Año"
                    v-model="data.year" class="mr-2 mt-2"></v-text-field>
                <v-autocomplete :items="meses" item-text="text" item-value="id" :rules="validar" autocomplete="off"
                    hide-details single-line label="Mes" v-model="data.month" class="mr-2 mt-2"></v-autocomplete>
                <c-btn-table-reload @click="obtenerPago()" v-if="permission.reporte" class="mr-2"></c-btn-table-reload>
                <c-btn-table-download @click="descargarPago()" v-if="permission.reporte"></c-btn-table-download>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table
            :headers="headers"
            :items="getArchiviPago"
            :loading="isArchivoPagoLoading"
        ></v-data-table>
    </div>
</template>
<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import { PAGO_SALARIO } from "@/constants/forms";
import {
    PUEDE_AGREGAR,
} from "@/constants/permissions";
import { mapActions, mapGetters } from "vuex";
export default {
    data() {
        return {
            validar: [(v) => !!v || "Seleccion un campo"],
            permission: {
                reporte: false,
            },
            data: {
                year: 2025,
                month: 1
            },
            consulta: {
                desde: null,
                hasta: null
            },
            meses: [
                {
                    id: 1,
                    text: "Enero"
                },
                {
                    id: 2,
                    text: "Febrero"
                },
                {
                    id: 3,
                    text: "Marzo"
                },
                {
                    id: 4,
                    text: "Abril"
                },
                {
                    id: 5,
                    text: "Mayo"
                },
                {
                    id: 6,
                    text: "Junio"
                },
                {
                    id: 7,
                    text: "Julio"
                },
                {
                    id: 8,
                    text: "Agosto"
                },
                {
                    id: 9,
                    text: "Septiembre"
                },
                {
                    id: 10,
                    text: "Octubre"
                },
                {
                    id: 11,
                    text: "Noviembre"
                },
                {
                    id: 12,
                    text: "Diciembre"
                },
            ],
            headers: [
                {
                    text: "Nombre",
                    align: "left",
                    value: "nombre"
                },
                {
                    text: "Cuenta",
                    align: "left",
                    value: "cuenta"
                },
                {
                    text: "Monto",
                    align: "right",
                    value: "monto"
                }
            ]
        }
    },
    computed: {
        ...mapGetters("salario", ["getArchivoPago", "isArchivoPagoLoading"]),
    },
    created() {
        this.$store.commit("SET_LAYOUT", "principal-layout")
        const permiso = getPermission(PAGO_SALARIO).permiso
        this.permission.reporte = permiso[PUEDE_AGREGAR]
    },
    methods: {
        ...mapActions("salario", ["fetchArchivoPago"]),
        obtenerPago() {
            this.fetchArchivoPago(this.consulta)
        }
    }
}
</script>
<template>
  <div>
    <div class="d-md-flex flex-row justify-space-between hidden-sm-and-down">
      <div class="title hidden-sm-and-down">Agricultura</div>
      <v-sheet class="d-flex justify-end mb-2">
        <v-slide-group multiple show-arrows>
          <v-slide-item
            ><v-btn
              active-class="deep-purple--text accent-4"
              exact
              text
              tile
              to="/agricultura/campo"
              >Mapa de Lotes</v-btn
            ></v-slide-item
          >
          <v-slide-item
            ><v-btn
              active-class="deep-purple--text accent-4"
              exact
              text
              tile
              to="/agricultura/sensor"
              >Sensores</v-btn
            ></v-slide-item
          >
          <v-slide-item
            ><v-btn
              active-class="deep-purple--text accent-4"
              exact
              text
              tile
              to="/agricultura/campana"
              >Campaña</v-btn
            ></v-slide-item
          >

          <v-slide-item>
            <v-menu bottom left offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  tile
                  class="align-self-center"
                  v-bind="attrs"
                  v-on="on"
                >
                  Transacciones
                  <v-icon right>arrow_drop_down</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(sub, subkey) in menu.transaccion"
                  :key="subkey"
                  :to="sub.route"
                  active-class="deep-purple--text accent-4"
                  >{{ sub.title }}</v-list-item
                >
              </v-list>
            </v-menu>
          </v-slide-item>
          <v-slide-item>
            <v-menu bottom left offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  tile
                  class="align-self-center"
                  v-bind="attrs"
                  v-on="on"
                >
                  Reportes
                  <v-icon right>arrow_drop_down</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(sub, subkey) in menu.reporte"
                  :key="subkey"
                  :to="sub.route"
                  exact
                  active-class="deep-purple--text accent-4"
                  >{{ sub.title }}</v-list-item
                >
              </v-list>
            </v-menu>
          </v-slide-item>
          <v-slide-item>
            <v-menu bottom left offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  tile
                  class="align-self-center"
                  v-bind="attrs"
                  v-on="on"
                >
                  Mantenimientos
                  <v-icon right>arrow_drop_down</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(sub, subkey) in menu.mantenimientos"
                  :key="subkey"
                  :to="sub.route"
                  active-class="deep-purple--text accent-4"
                  >{{ sub.title }}</v-list-item
                >
              </v-list>
            </v-menu>
          </v-slide-item>
          <v-slide-item v-if="!configRRHH">
            <v-btn
              tile
              text
              exact
              active-class="deep-purple--text accent-4"
              to="/agricultura/settings"
              ><v-icon>settings</v-icon></v-btn
            >
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </div>
    <v-divider></v-divider>
   
      <transition>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
   
  </div>
</template>

<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import { INFORME_CAJA_CUENTA, ZONAS_CULTIVO } from "@/constants/forms";
import { SALDO_CAJA_CUENTA } from "@/constants/permissions";
import { mensaje } from "@/services/notify.service";

export default {
  data() {
    return {
      permission: {
        Saldos: false,
      },
      menu: {
        mantenimientos: [
          {
            title: "Zonas de Cultivo",
            route: "/agricultura/mantenimiento/lotegrupo",
          },
          {
            title: "Tipo Pico",
            route: "/agricultura/mantenimiento/tipopico",
          },
          {
            title: "Aplicador",
            route: "/agricultura/mantenimiento/aplicador",
          },
          {
            title: "Parcelas",
            route: "/agricultura/mantenimiento/parcelas",
          },
          {
            title: "Ingenieros",
            route: "/agricultura/mantenimiento/ingenieros",
          },
          {
            title: "Validacion",
            route: "/agricultura/mantenimiento/validacion",
          }
        ],
        transaccion: [
          {
            title: "Insumos",
            route: "/agricultura/transaccion/insumos",
          },
          {
            title: "Venta de Granos",
            route: "/agricultura/transaccion/ventagrano",
          },
          {
            title: "Preventa de Granos",
            route: "/agricultura/transaccion/preventagrano",
          },
          {
            title: "Canje Avanzado",
            route: "/agricultura/transaccion/canje",
          }, 
          {
            title: "Canje Simple",
            route: "/agricultura/transaccion/canjesimple",
          }, 
          {
            title: "Recetario",
            route: "/agricultura/transaccion/recetario",
          }, 
          {
            title: "Reajuste",
            route: "/agricultura/transaccion/reajuste",
          }
        ],
        reporte: [
          {
            title: "Avanzado",
            route: "/agricultura/report",
          },
          {
            title: "Lote",
            route: "/agricultura/report/lote",
          },
          {
            title: "Indice Historico",
            route: "/agricultura/report/index",
          },
          {
            title: "Estimacion de Costos",
            route: "/agricultura/report/costos",
          },
          {
            title: "Senave",
            route: "/agricultura/report/senave",
          },
          {
            title: "Calendario",
            route: "/agricultura/report/calendario",
          },
          {
            title: "Indice Lote",
            route: "/agricultura/report/indicelote",
          },
          {
            title: "Ultima Aplicacion",
            route: "/agricultura/report/ultimaaplicacion",
          }
        ],
      },
    };
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
  },
  mounted() {
    const permission = getPermission(INFORME_CAJA_CUENTA).permiso;
    this.permission = {
      saldos: permission[SALDO_CAJA_CUENTA],
    };
  },
  computed: {
    configRRHH() {
      const permissionConfigRRHH = getPermission(ZONAS_CULTIVO).permiso;
      return mensaje.permission(permissionConfigRRHH);
    },
  },
};
</script>

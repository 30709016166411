
export default {
  getSalario: (state) => {
    return state.salario
  },
  getArchivoPago: (state) => {
    return state.archivoPago
  },
  isArchivoPagoLoading: (state) => {
    return state.isArchivoPagoLoading
  },
  isSalarioLoading: (state) => {
    return state.isLoading
  }
}

export default [

    {
        path: '/rrhh',
        component: () => import( /* webpackChunkName: "Informe Compra Venta" */ '@/views/RRHH/Root.vue'),
        children: [
            {
                path: '/',
                name: 'Recursos Humanos',
                component: () => import( /* webpackChunkName: "List" */ '@/views/RRHH/Inicio/Inicio.vue')
            },
            {
                path: 'funcionarios',
                name: 'Funcionarios',
                component: () => import( /* webpackChunkName: "List" */ '@/views/RRHH/Funcionarios/Funcionarios.vue')
            },
            {
                path: 'asistencias',
                name: 'Asistencias',
                component: () => import( /* webpackChunkName: "List" */ '@/views/RRHH/Asistencia/Asistencia.vue'),
            },
            {
                path: 'asistencias/:id',
                name: 'Asistencia Funcionario',
                component: () => import( /* webpackChunkName: "Sector" */ '@/views/RRHH/Asistencia/Lista.vue'),
             
            },
            {
                path: 'mantenimiento',
                component: () => import( /* webpackChunkName: "Salario" */ '@/views/RRHH/Mantenimiento/index.vue'),
                children: [
                    {
                        path: 'sector',
                        name: 'Sector',
                        component: () => import( /* webpackChunkName: "Sector" */ '@/views/RRHH/Mantenimiento/Sector/Sector.vue')
                    },
                    {
                        path: 'nivel',
                        name: 'Nivel de Sector',
                        component: () => import( /* webpackChunkName: "Sector" */ '@/views/RRHH/Mantenimiento/NivelSector/NivelSector.vue')
                    },
                    {
                        path: 'concepto',
                        name: 'Conceptos Salariales',
                        component: () => import( /* webpackChunkName: "Sector" */ '@/views/RRHH/Mantenimiento/ConceptoSalario/ConceptoSalario.vue')
                    },
                    {
                        path: 'estadoasistencia',
                        name: 'Estado Asistencia',
                        component: () => import( /* webpackChunkName: "Sector" */ '@/views/RRHH/Mantenimiento/EstadoAsistencia/EstadoAsistencia.vue')
                    },
                    {
                        path: 'relojbio',
                        name: 'Reloj Biometrico',
                        component: () => import( /* webpackChunkName: "Sector" */ '@/views/RRHH/Mantenimiento/RelojBiometrico/RelojBiometrico.vue')
                    },
                    {
                        path: 'poligono',
                        name: 'Poligono de Marcacion',
                        component: () => import( /* webpackChunkName: "Poligono de Marcacion" */ '@/views/RRHH/Mantenimiento/PoligonoMarcacion/PoligonoMarcacion.vue')
                    },
                    {
                        path: 'feriado',
                        name: 'Feriado',
                        component: () => import( /* webpackChunkName: "Feriado" */ '@/views/RRHH/Mantenimiento/Feriado/Feriado.vue')
                    }
                ]
            },
            {
                path: 'operacion',
                component: () => import( /* webpackChunkName: "Salario" */ '@/views/RRHH/Pago/index.vue'),
                children: [
                    {
                        path: 'ausencias',
                        name: 'Ausencias/Vacaciones',
                        component: () => import( /* webpackChunkName: "List" */ '@/views/RRHH/Operaciones/Justificacion/Justificacion.vue')
                    }
                ]
            },
            {
                path: 'pago',
                component: () => import( /* webpackChunkName: "Salario" */ '@/views/RRHH/Pago/index.vue'),
                children: [
                    {
                        path: 'salario',
                        name: 'Pago de Salario',
                        component: () => import( /* webpackChunkName: "List" */ '@/views/RRHH/Pago/Salario/Salario.vue')
                    },
                    {
                        path: 'adelanto',
                        name: 'Pago de Adelanto',
                        component: () => import( /* webpackChunkName: "List" */ '@/views/RRHH/Pago/Adelanto/Adelanto.vue')
                    },
                    {
                        path: 'aguinaldo',
                        name: 'Pago de Aguinaldo',
                        component: () => import( /* webpackChunkName: "List" */ '@/views/RRHH/Pago/Aguinaldo/Aguinaldo.vue')
                    },
                    {
                        path: 'despido',
                        name: 'Despido/Renuncia',
                        component: () => import( /* webpackChunkName: "List" */ '@/views/RRHH/Pago/Despido/Despido.vue')
                    },
                    {
                        path: 'archivopago',
                        name: 'Generar Archivo Pago',
                        component: () => import( /* webpackChunkName: "List" */ '@/views/RRHH/Pago/ArchivoPago/ArchivoPago.vue')
                    }
                ]
            },
            {
                path: 'reporterrhh',
                name: 'Reporte RRHH',
                component: () => import( /* webpackChunkName: "Reporte" */ '@/views/RRHH/Reporte/Reporte.vue'),
            },
            {
                path: 'settings',
                name: 'Configuraciones RRHH',
                component: () => import( /* webpackChunkName: "List" */ '@/views/RRHH/Settings/Settings.vue')
            },
        ]
    }
]